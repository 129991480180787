// assetsの中でGCS/imgixを使っているものを取得する

// 以下GCSに入っているものリスト

// リストページのFVの画像
// aga.png
// clinic.png
// dentistry.png
// dermatology.png
// hairremoval.png

// ロゴ
// logo.png
// logo_white.png

export default (fileName: string, options: Object = {}) => {
  const defaultOptions = {
    auto: 'format',
  }
  const imgixOptions = { ...defaultOptions, ...options }
  const queryString = Object.entries(imgixOptions)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  return `https://kireireport.imgix.net/assets/${fileName}.png?${queryString}`
}
